<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-title>
        Lista de Produtos
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listaProdutos"
        :search="search"
      >
        <template v-slot:item.vlUnitario="{ item }">
          {{ formatPrice(item.vlUnitario) }}
        </template>
        <template v-slot:item.vlUnitario2="{ item }">
          {{ formatPrice(item.vlUnitario2) }}
        </template>
        <template v-slot:item.vlUnitario3="{ item }">
          {{ formatPrice(item.vlUnitario3) }}
        </template>
        <template v-slot:item.fisico="{ item }">
          <v-chip
            :color="colorGridEstoque(item)"
            dark
          >
            {{ formatPrice(item.fisico) }}
          </v-chip>
        </template>
        <template v-slot:item.reservado="{ item }">
          {{ formatPrice(item.reservado) }}
        </template>
        <template v-slot:item.estoque="{ item }">
          {{ formatPrice(item.estoque) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="onClickSelect(item)"
          >
            mdi-check
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import produtoRep from './../../components/services/produtoRepository'

  export default {
    props: {
      preorcamento: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        search: '',
        listaProdutos: [],
        headers: [
          {
            text: 'Nome',
            align: 'start',
            value: 'descricao',
          },
          { text: 'Unid.', value: 'cdUnidade' },
          { text: 'Vl. Unit.', align: 'end', value: 'vlUnitario' },
          { text: 'Vl. Unit.2', align: 'end', value: 'vlUnitario2' },
          { text: 'Vl. Unit.3', align: 'end', value: 'vlUnitario3' },
          { text: 'Est.Físico', align: 'end', value: 'fisico' },
          { text: 'Reservado', align: 'end', value: 'reservado' },
          { text: 'Estoque', align: 'end', value: 'estoque' },
          { text: '', value: 'actions', sortable: false },
        ],
      }
    },
    async created () {
      this.$store.commit('setSplashScreen', true)
      await this.getLista()
      setTimeout(() => {
        // this.$refs.txtcodigo.focus()
        this.$store.commit('setSplashScreen', false)
      }, 1500)
    },
    methods: {
      onClickSelect (value) {
        this.$emit('onSelectProduto', value)
      },
      async getLista () {
        await produtoRep.getList().then(response => {
          // listaUsuarios = response.data
          if (response.data.error === 0) {
            this.listaProdutos = response.data.result
          }
        }).catch(() => {
        })
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      colorGridEstoque (item) {
        if (item.fisico === 0) {
          return 'error'
        } else if (item.fisico < item.qtEstoqueMin) {
          return 'warning'
        } else {
          return 'success'
        }
      },
      validarAddProdutoGrid (dados) {
        var estoqueAtual = 0
        if (this.produto.cdunidade !== this.produto.cdunidadeVenda && this.produto.vlpesound !== 0) {
          estoqueAtual = this.produto.estoqueQtEstoque * this.produto.vlpesound
        } else {
          estoqueAtual = this.produto.estoqueQtEstoque
        }

        if (this.validarItemExiste(dados.idProduto)) {
          this.$swal({
            icon: 'warning',
            text: 'Produto já lançado no PEDIDO',
          })
          return false
        } else if (this.qtd === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Informe a Quantidade!',
          })
          setTimeout(() => {
            // this.$refs.txtcodigo.focus()
            this.$refs.txtQtd.$children[0].focus()
          }, 1500)
          return false
        } else if (this.produto.cdunidade !== this.produto.cdunidadeVenda && this.produto.vlpesound === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Produto SEM Peso da Unidade Cadastrado!',
          })
          return false
        } else if (estoqueAtual < this.qtd) {
          this.$swal({
            icon: 'warning',
            text: 'Produto estoque menor que a venda! Estoque:' + this.formatPrice(estoqueAtual),
          })
          return false
        } else {
          return true
        }
      },
    },
  }
</script>

<style>

</style>
