<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card
      elevation="15"
    >
      <v-card-text>
        <h3
          v-if="flNaoClienteSelecionado"
          class="primary--text"
        >
          Selecione o Cliente
        </h3>
        <h3
          v-else
          class="primary--text mt-1"
        >
          {{ NomeFornecedor }}
        </h3>
        <v-row dense>
          <v-col
            cols="6"
            sm="3"
            md="3"
          >
            <v-text-field
              v-model="CpfCnpj"
              label="CPF ou CNPJ"
              placeholder="CPF ou CNPJ"
              outlined
              dense
              hide-details
              append-icon="mdi-account-search-outline"
              @click:append="dialogCliente = true"
            />
          </v-col>
          <v-col
            cols="4"
            sm="2"
            md="2"
          >
            <compLoja :dense="true" />
          </v-col>
          <v-col
            cols="4"
            sm="2"
            md="1"
          >
            <v-select
              :items="tipoVenda"
              label="Tipo:"
              dense
              outlined
              hide-details
            />
          </v-col>
          <v-col
            cols="4"
            sm="2"
            md="2"
          >
            <compPrazoPagto :dense="true" />
          </v-col>
          <v-col
            cols="4"
            sm="1"
            md="1"
          >
            <compPrefixoPagto :dense="true" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      elevation="15"
      class="mt-1"
    >
      <v-card-title>{{ titleProduto }}</v-card-title>
      <v-card-text>
        <v-row
          dense
        >
          <v-col
            cols="4"
            sm="3"
            md="2"
          >
            <v-text-field
              ref="txtcodigo"
              v-model="produto.codigo"
              label="Código"
              outlined
              dense
              hide-details
              append-icon="mdi-magnify"
              @blur="getProduto"
              @click:append="dialogProduto = true"
            />
          </v-col>
          <v-col
            cols="3"
            sm="1"
            md="1"
          >
            <v-text-field
              v-if="!flexistPesoUnd"
              ref="txtunid"
              v-model="produto.cdUnidade"
              label="Und."
              outlined
              dense
              hide-details
              disabled
            />
            <v-select
              v-if="flexistPesoUnd"
              :items="itemUnd"
              label="Und."
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="3"
            sm="2"
            md="1"
          >
            <vuetify-number
              ref="txtQtd"
              v-model="produto.qtd"
              class="text-right"
              label="Qtd."
              dense
              :disabled="false"
              :outlined="outlined"
              :clearable="clearable"
              :value-when-is-empty="valueWhenIsEmpty"
              :options="options"
              hide-details
            />
          </v-col>
          <v-col
            cols="4"
            sm="2"
            md="1"
          >
            <vuetify-number
              v-model="produto.vlUnitario"
              class="text-right"
              label="Vl.Unit"
              dense
              :readonly="readonly"
              :outlined="outlined"
              :clearable="clearable"
              :value-when-is-empty="valueWhenIsEmpty"
              :options="options"
              hide-details
            />
          </v-col>
          <v-col
            cols="4"
            sm="2"
            md="2"
          >
            <vuetify-number
              v-model="SomatotalProduto"
              label="Total"
              readonly
              dense
              :outlined="outlined"
              :options="options"
              hide-details
            />
          </v-col>
          <v-col
            cols="1"
            class="mt-2"
          >
            <v-btn
              color="primary"
              dark
              small
              @click="addProdutoGrid"
            >
              <v-icon
                dark
              >
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      elevation="1"
      class="mt-1"
    >
      <div class="d-flex justify-end">
        <label style="font-size:30px">
          Total: <span class="ml-3 mr-2">{{ formatPrice(totalVenda) }}</span>
        </label>
      </div>
    </v-card>
    <v-card
      class="mt-1"
    >
      <v-card-title>
        Lista de Produtos
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listaProduto"
        :search="search"
      >
        <template v-slot:item.vlUnitario="{ item }">
          {{ formatPrice(item.vlUnitario) }}
        </template>
        <template v-slot:item.subTotal="{ item }">
          {{ formatPrice(item.subTotal) }}
        </template>
        <template v-slot:item.total="{ item }">
          {{ formatPrice(item.total) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="excluirDialog(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialogCliente"
      persistent
      scrollable
      max-width="850"
    >
      <v-card
        height="1200"
      >
        <modalCliente
          v-if="dialogCliente"
          @onSelectCliente="onSelectCliente"
        />
        <v-card-actions>
          <v-btn
            color="primary"
            @click="dialogCliente = false"
          >
            Sair
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogProduto"
      persistent
      scrollable
      max-width="950px"
    >
      <v-card
        height="1200"
        rounded="false"
      >
        <modalProduto
          v-if="dialogProduto"
          @onSelectProduto="onSelectProduto"
        />
        <v-card-actions>
          <v-btn
            color="primary"
            @click="dialogProduto = false"
          >
            Sair
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import modalCliente from './../../componentes/modal-listacliente'
  import modalProduto from './../../componentes/modal-listaprodutos'
  import compPrazoPagto from './../../componentes/componente-prazopagto'
  import compPrefixoPagto from './../../componentes/componente-prefixopagto'
  import compLoja from './../../componentes/componente-loja'
  import produtoRep from './../../../components/services/produtoRepository'

  export default {
    components: {
      modalCliente,
      modalProduto,
      compPrazoPagto,
      compPrefixoPagto,
      compLoja,
    },
    data () {
      return {
        titleProduto: 'Produto',
        itemUnid: ['UND'],
        tipoVenda: ['A', 'X'],
        unidConv: '',
        dialogCliente: false,
        dialogProduto: false,
        NomeFornecedor: '',
        CpfCnpj: '',
        valid: true,
        search: '',
        flNaoClienteSelecionado: true,
        flUnidadeDiferenteCadastrada: true,
        dialog: false,
        edit: false,
        loading: false,
        loadingExcluir: false,
        dialogExcluir: false,
        totalVenda: 0.00,
        totalProduto: 0.00,
        quantidade: 0.00,
        headers: [
          {
            text: 'Nome',
            align: 'start',
            value: 'descricao',
          },
          { text: 'Und.', value: 'und' },
          { text: 'Qtd.', align: 'end', value: 'qtd' },
          { text: 'Vl.Unitário', align: 'end', value: 'vlUnitario' },
          { text: 'Sub. Total', align: 'end', value: 'subTotal' },
          { text: 'Total', align: 'end', value: 'total' },
          { text: '', value: 'actions', sortable: false },
        ],
        produto: {
          idProduto: 0,
          codigo: '',
          descricao: '',
          und: '',
          qtd: 0,
          vlUnitario: 0.00,
          vlUnitario2: 0.00,
          vlUnitario3: 0.00,
          subTotal: 0.00,
          total: 0.00,
          vlPesound: 0.00,
        },
        listaProduto: [],
        money: {
          decimal: '.',
          thousands: '',
          precision: 2,
          masked: false,
        },
        // Validações
        obrigatorioRules: [
          v => !!v || 'Campo é obrigatorio',
          v => (v && v.length <= 30) || 'Ter menos de 30 caracteres',
        ],
        // Componente Monetario
        readonly: true,
        disabled: true,
        outlined: true,
        clearable: false,
        valueWhenIsEmpty: '',
        options: {
          locale: 'pt-BR',
          prefix: '',
          suffix: '',
          length: 9,
          precision: 2,
        },
        flexistPesoUnd: false,
        itemUnd: ['KG', 'UN'],
        itemPrazoPagto: [],
      }
    },
    computed: {
      SomatotalProduto: function () {
        if (this.produto.qtd > 0) {
          if (this.produto.vlUnitario > 0) {
            this.$nextTick(() => this.$refs.txtunid.focus())
            return (parseFloat(this.produto.qtd) * parseFloat(this.produto.vlUnitario)).toFixed(2)
          } else {
            return 0.00
          }
        } else {
          return 0.00
        }
      },
    },
    mounted: function () {
      this.$store.commit('setSplashScreen', false)
    },
    methods: {
      clearData () {
        this.dialog = false
        this.edit = false
        this.loading = false
        this.grupo = {
          idGrupo: 0,
          nmgrupo: '',
        }
      },
      canceledEdit () {
        this.clearData()
      },
      getProduto () {
        this.$store.commit('setSplashScreen', true)
        produtoRep.getId(this.produto.codigo).then(response => {
          this.titleProduto = response.data.nmItem
          this.produto = {
            idProduto: response.data.item,
            item: response.data.item,
            codigo: response.data.item,
            descricao: response.data.descricao,
            cdUnidade: response.data.cdUnidade,
            qtd: 0,
            vlUnitario: response.data.vlUnitario,
            vlUnitario2: response.data.vlUnitario2,
            vlUnitario3: response.data.vlUnitario3,
            subTotal: parseFloat(this.produto.qtd) * parseFloat(this.produto.vlUnitario),
            total: parseFloat(this.produto.qtd) * parseFloat(this.produto.vlUnitario),
            vlPesound: response.data.vlPesound,
          }
          this.addProduto(this.produto)
          this.$store.commit('setSplashScreen', false)
        }).catch(() => {
          this.$store.commit('setSplashScreen', false)
        })
      },
      addProduto (produto) {
        this.produto.codigo = produto.item
        this.produto.descricao = produto.descricao
        this.produto.cdUnidade = produto.cdUnidade
        this.produto.vlUnitario = produto.vlUnitario
        this.produto.vlPesound = produto.vlPesound
        if (parseFloat(produto.vlPesound) !== 0) {
          this.flexistPesoUnd = true
        } else {
          this.flexistPesoUnd = false
        }
      },
      addProdutoGrid () {
        this.listaProduto.push({
          descricao: this.produto.descricao,
          und: this.produto.cdUnidade,
          qtd: this.produto.qtd,
          vlUnitario: this.produto.vlUnitario,
          subTotal: parseFloat(this.produto.qtd) * parseFloat(this.produto.vlUnitario),
          total: parseFloat(this.produto.qtd) * parseFloat(this.produto.vlUnitario),
        })
        this.calcularTotal()
        this.clearColumns()
        setTimeout(() => {
          this.$refs.txtcodigo.focus()
        }, 500)
      },
      onSelectCliente (cliente) {
        this.dialogCliente = false
        this.NomeFornecedor = cliente.nmCliente
        this.CpfCnpj = cliente.cdCpfCgcFormatado
        this.flNaoClienteSelecionado = false
      },
      onSelectProduto (produto) {
        // var local = this
        if (produto.vlPesound > 0) {
          this.flUnidadeDiferenteCadastrada = false
        }
        this.dialogProduto = false
        this.addProduto(produto)
        setTimeout(() => {
          // this.$refs.txtcodigo.focus()
          this.$refs.txtQtd.$children[0].focus()
        }, 500)
      },
      calcularTotal () {
        var total = 0.00
        for (let index = 0; index < this.listaProduto.length; index++) {
          total += this.listaProduto[index].total
        }
        this.totalVenda = total
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },

      clearColumns () {
        this.titleProduto = 'Produto'
        this.produto = {
          idProduto: 0,
          codigo: '',
          descricao: '',
          cdUnidade: '',
          qtd: 0,
          vlUnitario: 0.00,
          vlUnitario2: 0.00,
          vlUnitario3: 0.00,
          subTotal: 0.00,
          total: 0.00,
          vlPesound: 0.00,
        }
      },
      validarItemExiste (dados) {
        var exists = this.listaProduto.some(function (item) {
          return item.idProduto === dados
        })
        return exists
      },
      validarAddProdutoGrid (dados) {
        var estoqueAtual = 0
        if (this.flexistPesoUnd === false) {
          if (this.produto.cdunidade !== this.produto.cdunidadeVenda && this.produto.vlpesound !== 0) {
            estoqueAtual = this.produto.estoqueQtEstoque * this.produto.vlpesound
          } else {
            estoqueAtual = this.produto.estoqueQtEstoque
          }
        } else { estoqueAtual = this.produto.estoqueQtEstoque }

        if (this.validarItemExiste(dados.idProduto)) {
          this.$swal({
            icon: 'warning',
            text: 'Produto já lançado no PEDIDO',
          })
          return false
        } else if (this.qtd === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Informe a Quantidade!',
          })
          setTimeout(() => {
            // this.$refs.txtcodigo.focus()
            this.$refs.txtQtd.$children[0].focus()
          }, 1500)
          return false
        } else if (this.produto.cdunidade !== this.produto.cdunidadeVenda && this.produto.vlpesound === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Produto SEM Peso da Unidade Cadastrado!',
          })
          return false
        } else if (estoqueAtual < this.qtd) {
          this.$swal({
            icon: 'warning',
            text: 'Produto estoque menor que a venda! Estoque:' + this.formatPrice(estoqueAtual),
          })
          return false
        } else {
          return true
        }
      },
    },
  }
</script>

<style>

</style>
